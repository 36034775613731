import { PageProps, graphql } from 'gatsby'
import React from 'react'

import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'

import { ScrollProgressProvider } from '@talentinc/gatsby-theme-ecom/components/Post/ScrollProgress'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import BasePostTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/Post'
import {
  PageContextType,
  PageDataContextType,
} from '@talentinc/gatsby-theme-ecom/types/page'
import { PostResumeSample } from '@talentinc/gatsby-theme-ecom/types/post'

import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'

const PostResumeSampleTemplate: React.FC<
  PageProps<
    { postResumeSample: PostResumeSample } & PageDataContextType,
    PageContextType
  >
> = (props) => {
  const { postResumeSample, ...pageData } = props.data

  return (
    <Layout pageTitle={postResumeSample.title} pageData={pageData}>
      {postResumeSample.seo ? (
        <SEO
          {...postResumeSample.seo}
          post={postResumeSample}
          noIndex={props.pageContext.noIndex}
        />
      ) : (
        <SEO noIndex={props.pageContext.noIndex} />
      )}
      <ScrollProgressProvider>
        <HeaderV2 showBlogNavigation />
        <BasePostTemplate post={postResumeSample} />
      </ScrollProgressProvider>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query PostResumeSamplesBySlugAndBrand($slug: String!, $brandName: String!) {
    postResumeSample: contentfulPostResumeSample(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...PostResumeSample
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PostResumeSampleTemplate
